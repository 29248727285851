<template>
  <div
    class="userguanli"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <div class="top-select">
      <el-form :model="SerachInput" label-width="120px">
        <div style="display: flex">
          <el-form-item label="最近通讯时间">
            <el-date-picker
              v-model="overallForm.time"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
            ></el-date-picker>
          </el-form-item>
          <!-- <el-form-item label="最后在线时间">
          <el-col>
            <el-date-picker
              v-model="overallForm.Onlinetime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
            ></el-date-picker>
          </el-col>
        </el-form-item> -->
          <el-form-item label="产品名称">
            <el-select
              v-model="SerachInput.productId"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in optionssex"
                :key="item.id"
                :label="item.productName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="IMEI" style="margin-right: 18px">
            <el-input
              v-model="SerachInput.imei"
              placeholder="请输入内容"
              style="width: 220px"
              clearable
            ></el-input>
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item label="内置号码" style="margin-right: 10px">
            <el-input
              v-model="SerachInput.localPhone"
              placeholder="请输入内容"
              style="width: 220px"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label-width="60px">
            <img
              src="../../../assets/search.png"
              alt
              style="width: 49px"
              @click="search"
            />
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="tables">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column
          prop="productName"
          label="产品名称"
          width="140"
        ></el-table-column>
        <el-table-column prop="imei" label="IMEI" width="150"></el-table-column>
        <el-table-column
          prop="iccid"
          label="ICCID"
          width="190"
        ></el-table-column>
        <el-table-column prop="localPhone" label="内置号码" width="150">
        </el-table-column>
        <!-- <el-table-column prop="quitDate" label="添加日期" width="150">
        </el-table-column> -->
        <el-table-column prop="lastOnlineDate" label="最近通讯时间" width="160">
        </el-table-column>
        <el-table-column prop="electric" label="电量" width="100">
        </el-table-column>
        <el-table-column prop="signal" label="信号强度" width="120">
        </el-table-column>
      </el-table>
    </div>
    <div class="totals">
      <!-- 左 -->
      <div class="left">
        <p>
          总用户数
          <span class="spanto">{{ totalCount }}</span
          >人
        </p>
      </div>
      <!-- 右 -->
      <div class="total">
        <!-- 分页 -->
        <div class="pages">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-size="pageSize"
            :current-page="pageCount"
            layout="total, prev, pager, next"
            :total="totalCount"
          >
          </el-pagination>
        </div>
        <!-- 按钮 -->
        <!-- <div class="buttons">
          <el-button type="text" @click="add">添加用户</el-button>
        </div> -->
      </div>
    </div>

    <!-- 增加弹框-->
    <div class="accountadd">
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="50%"
        :before-close="clearUserForm"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
          :inline="true"
        >
          <el-form-item label="用户编号" prop="name">
            <el-input
              v-model="ruleForm.userName"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input v-model="ruleForm.name" style="width: 196px"></el-input>
          </el-form-item>
          <!-- <el-form-item label="是否为客服" prop="">
            <el-select
              v-model="ruleForm.cusSerSign"
              placeholder="请选择"
              @change="getUserValueCus(ruleForm.cusSerSign)"
            >
              <el-option
                v-for="item in optionsCus"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="性别" prop="">
            <el-input v-model="ruleForm.cno" style="width: 196px"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="">
            <el-input
              v-model="ruleForm.enterpriseCode"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系方式" prop="">
            <el-input
              v-model="ruleForm.cnoPassword"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="地址" prop="">
            <el-input
              v-model="ruleForm.bindTel"
              style="width: 196px"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="clearUserForm">取 消</el-button>
          <el-button type="primary" @click="addUserList">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 修改弹框-->
    <div class="accountadd">
      <el-dialog
        :title="title"
        :visible.sync="dialogVisibleChange"
        width="50%"
        :before-close="clearUserFormChange"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
          :inline="true"
        >
          <el-form-item label="用户编号" prop="name">
            <el-input
              v-model="ruleForm.userName"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input v-model="ruleForm.name" style="width: 196px"></el-input>
          </el-form-item>
          <!-- <el-form-item label="是否为客服" prop="">
            <el-select
              v-model="ruleForm.cusSerSign"
              placeholder="请选择"
              @change="getUserValueCus(ruleForm.cusSerSign)"
            >
              <el-option
                v-for="item in optionsCus"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="性别" prop="">
            <el-input v-model="ruleForm.cno" style="width: 196px"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="">
            <el-input
              v-model="ruleForm.enterpriseCode"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系方式" prop="">
            <el-input
              v-model="ruleForm.cnoPassword"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="地址" prop="">
            <el-input
              v-model="ruleForm.bindTel"
              style="width: 196px"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="clearUserFormChange">取 消</el-button>
          <el-button type="primary" @click="changeUserLs">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 二次弹框 -->
    <el-dialog title="提示" :visible.sync="dialogVisibleTwo" width="30%">
      <span>确认删除此信息吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleTwo = false">取 消</el-button>
        <el-button type="primary" @click="deleteTwo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { apiShan } from "../../../api1/shanConfig";
export default {
  data() {
    return {
      clientHeight: document.body.clientHeight,
      SerachInput: {},
      overallForm: {
        time: [],
        Onlinetime: [],
      },
      optionssex: [],
      twoID: "",
      // 弹框
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisibleChange: false,
      dialogVisibleTwo: false,
      isShow: true,
      title: "添加用户",
      ruleForm: {},
      ruleForm1: {},
      pageSize: 10,
      totalCount: 100,
      pageCount: 1,
      optionsCus: [],
      value: "",
      orgIds: null,
      //   table
      tableData: [],
      rules: {
        userPass: [
          { required: true, message: "请输入ID名称", trigger: "blur" },
          {
            min: 6,
            max: 12,
            message: "长度在 6 到 12 个字符",
            trigger: "blur",
          },
        ],
      },
      roIdAA: null,
      userId: "",
    };
  },
  // components: {
  //   rolemanage,
  //   addaccount
  // },
  created() {
    this.menulist();
    this.proL();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    //用户列表
    menulist() {
      apiShan
        .geteqList({
          pageSize: this.pageSize,
          pageCount: this.pageCount,
          // startTime:
          //   this.overallForm.time == null ? "" : this.overallForm.time[0],
          // endTime:
          //   this.overallForm.time == null ? "" : this.overallForm.time[1],
          // localPhone: this.SerachInput.localPhone,
          // imei: this.SerachInput.imei,
          // productId: this.SerachInput.productId,
        })
        .then((res) => {
          console.log("列表：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    search() {
      if (this.SerachInput.productId == ""||this.SerachInput.productId ==null) {
        apiShan
          .geteqList({
            pageSize: this.pageSize,
            pageCount: this.pageCount,
            startTime:
              this.overallForm.time == null ? "" : this.overallForm.time[0],
            endTime:
              this.overallForm.time == null ? "" : this.overallForm.time[1],
            localPhone: this.SerachInput.localPhone,
            imei: this.SerachInput.imei,
          })
          .then((res) => {
            console.log("列表：", res.data);
            if (res.data.code == 200) {
              this.tableData = res.data.result.data;
              this.totalCount = res.data.result.page.totalCount;
              this.pageSize = res.data.result.page.pageSize;
            } else {
              this.$message.error(res.data.msg);
            }
          });
      } else {
        apiShan
          .geteqList({
            pageSize: this.pageSize,
            pageCount: this.pageCount,
            startTime:
              this.overallForm.time == null ? "" : this.overallForm.time[0],
            endTime:
              this.overallForm.time == null ? "" : this.overallForm.time[1],
            localPhone: this.SerachInput.localPhone,
            imei: this.SerachInput.imei,
            productId: this.SerachInput.productId,
          })
          .then((res) => {
            console.log("列表：", res.data);
            if (res.data.code == 200) {
              this.tableData = res.data.result.data;
              this.totalCount = res.data.result.page.totalCount;
              this.pageSize = res.data.result.page.pageSize;
            } else {
              this.$message.error(res.data.msg);
            }
          });
      }
    },
    //添加用户
    add() {
      this.dialogVisible = true;
    },
    //添加用户
    addUserList() {
      this.dialogVisible = false;
    },
    //编辑用户
    changeUser(index, row) {
      console.log(index, row);
      this.dialogVisibleChange = true;
    },
    //编辑的确定
    changeUserLs() {
      this.dialogVisibleChange = false;
    },
    //删除用户
    handleEdit(index, row) {
      console.log(index, row);
      this.dialogVisibleTwo = true;
      this.twoID = row.id;
    },
    deleteTwo() {
      this.dialogVisibleTwo = false;
    },
    //用户详情
    details(index, row) {
      console.log(index, row);
      this.dialogVisible1 = true;
    },
    //关闭详情按钮
    detailsX() {
      this.dialogVisible1 = false;
      //清空表单数据
      this.clearUserForm();
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.menulist();
    },
    handleCurrentChange(pageCount) {
      // console.log(`当前页: ${val}`);
      this.pageCount = pageCount;
      this.menulist();
    },
    proL() {
      apiShan.proList({}).then((res) => {
        // console.log("列表：", res.data);
        if (res.data.code == 200) {
          this.optionssex = res.data.result;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    clearUserFormChange() {
      this.dialogVisibleChange = false;
    },
    clearUserForm() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.userguanli {
  // 搜索
  .sousuo {
    // background: red;
    display: flex;
    align-items: center;
    margin-left: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    .div1 {
      margin-right: 20px;
      .inputs {
        width: 231px;
        height: 33px;
        border: 1px solid #e5e5e5;
        margin-left: 5px;
      }
      .inputs:focus {
        outline: 1px solid #e5e5e5; //边框不用border，用outline
      }
      span {
        font-size: 14px;
        color: #909399;
        font-weight: 700;
      }
      .select {
        margin-left: 20px;
      }
    }
    .imgs {
      width: 49px;
    }
  }
  //   table
  .tables {
    position: relative;
    margin-left: 25px;
    margin-right: 40px;

    .tingyong {
      color: rgb(97, 97, 245);
    }
    .buts {
      width: 36px;
      height: 27px;
      margin: 0 10px;
    }
    .el-table {
      // max-height: 500px;
      // overflow: hidden;
      tr {
        th {
          font-size: 14px;
          border: none;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #909399;
        }
        td {
          border: none;
          text-align: center;
          font-size: 14px;
          color: #606266;
        }
      }
      .details {
        height: 27px;
        border-radius: 15px;
      }
    }
    .el-table::before {
      height: 0;
    }
    // .details {
    //   height: 30px;
    //   position: absolute;
    //   top: 12px;
    //   right: 1px;
    //   border-radius: 15px;
    // }
  }
  /deep/ .el-table .el-table__cell {
    padding: 12px 0;
    min-width: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    vertical-align: middle;
    position: relative;
    text-align: center;
  }
  /deep/ .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  /deep/ th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  .totals {
    display: flex;
    justify-content: space-between;
    margin-right: 100px;
    .left {
      p {
        font-size: 16px;
        display: inline-block;
        margin-right: 80px;

        margin-left: 50px;

        margin-top: 20px;
        color: #606266;
      }
      .spanto {
        color: #92aca7;
        margin-left: 17px;
        margin-right: 12px;
      }
    }
    .total {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 20px;
      margin-right: 50px;
      // 按钮
      .buttons {
        display: flex;
        .el-button {
          width: 130px;
          height: 40px;
          line-height: 40px;
          border-radius: 25px;
          margin-top: 20px;
          margin-right: 20px;
          background: #0085d0;
          color: #ffffff;
          font-size: 16px;
          padding: 0px;
        }
      }
      // page
      .pages {
        display: flex;
        align-items: center;
        .el-pagination {
          padding: 0;
          img {
            display: inline-block;
          }
          .lefts {
            margin-right: 25px;
          }
          .rights {
            float: right;
            margin-left: 25px;
          }
        }
      }
    }
  }
  // .el-input__inner {
  //   width: 50%;
  // }
  .lizhi {
    margin-left: -10px;
  }
  .account .el-input__inner {
    border: 1px solid #fff;
  }
  .el-form-item__label {
    margin-left: 60px;
  }
  .workState {
    margin: 70px 120px;
    span {
      margin-right: 15px;
    }
  }
  .sexState {
    position: absolute;
    right: 173px;
    top: 245px;
    span {
      margin-right: 15px;
    }
  }
  .jigouState {
    position: absolute;
    right: 173px;
    top: 180px;
    span {
      margin-right: 15px;
    }
  }
  .jueseState {
    position: absolute;
    left: 133px;
    top: 245px;
    span {
      margin-right: 15px;
    }
  }
  .dengState {
    position: absolute;
    left: 105px;
    top: 180px;
    span {
      margin-right: 15px;
    }
  }
  .workState1 {
    margin: 130px 120px;
    span {
      margin-right: 15px;
    }
  }
  // // 弹框
  // .el-dialog {
  //   border-radius: 18px;
  // }
  // .el-dialog__header {
  //   background: #eaeaea;
  //   border-radius: 18px 18px 0 0;
  // }
  // .el-dialog__close {
  //   color: transparent;
  //   background-image: url("../../assets/images/close.png");
  //   background-repeat: no-repeat;
  //   background-size: 14px;
  // }
  // .el-dialog__body {
  //   background: #eaeaea;
  //   border-radius: 0 0 18px 18px;
  //   padding: 10px 0px 0 0px;
  // }
  .accountdd {
    .el-dialog__header {
      background: #ffffff;
      border-radius: 18px 18px 0 0;
    }
    .el-dialog__body {
      background: #ffffff;
      border-radius: 0 0 18px 18px;
      padding: 10px 0px 0 0px;
    }
  }
  /deep/.el-input__inner {
    border-radius: 0;
    height: 33px !important;
    line-height: 33px !important;
  }
  /deep/.el-input__icon {
    height: 100%;
    width: 25px;
    text-align: center;
    transition: all 0.3s;
    line-height: 33px !important;
  }
  /deep/.el-form-item__label {
    color: #909399;
    text-align: center;
  }
  /deep/.el-dialog__body {
    padding: 0;
    margin-left: 2%;
  }
  /deep/.el-table td,
  .el-table th.is-leaf {
    border-bottom: none !important;
  }
  /deep/.el-table .cell {
    text-align: center !important;
  }
  /deep/.el-table thead tr th.is-leaf {
    border: none;
    border-right: none;
  }
}
</style>